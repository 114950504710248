import { loadableReady } from '@loadable/component';
import ReactDOM from 'react-dom';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

const handleGatsbyAnnouncer = () => {
  const gatsbyAnnouncer = document.querySelector('#gatsby-announcer');
  if (gatsbyAnnouncer) {
    gatsbyAnnouncer.setAttribute('role', 'navigation');
  }
};

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    ReactDOM.hydrate(element, container, callback);
  });
};

export const onInitialClientRender = () => {
  handleGatsbyAnnouncer();
};
